import axios from 'axios';

class ApiCore {

  constructor() {
    const baseURL = "https://bf1ym293l7.execute-api.eu-west-1.amazonaws.com/V1";
    this.instance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    this.instance.interceptors.request.use(
      function(config) {
        config.headers["Content-Type"] = 'application/json';
        config.headers["x-api-key"] = "GDHqmWyPR8tcEFMrjWqp6wpy6JCqb4SoBW58v2EQ";
        return config;
      },
      function(error) {
        return Promise.reject(error);
      }
    );
  }
}

export default ApiCore;
