import React from 'react';


import './templateOld.css';

const RATIO = 890 / 2253;

const TemplateOld = ({item}) => {
  // console.log(data);
  return (
    <div style={{width: "890px", position: "relative"}}>
      <img src={"https://cdn.ozwol.cloud/7CF7B37A3E/cardeditor_bmp/"+item.id+".bmp"} width="100%" alt="" />
      {/*<img src={"/"+item.objectID+".jpg"} width="100%" alt="" style={{
        position: "absolute",
        opacity: 0.5,
        zIndex: 2,
        top: 0,
        left: 0
      }}/>*/}
      {/*<div style={{zIndex: "999", position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%"}}></div>*/}
      {item.importJson && item.importJson.length > 0 ? item.importJson.map((field, i) =>
        <span key={i} className={"style-"+field['type']} data-x={field['x']} data-y={field['y']} style={{
          "position": "absolute",
          "top": field['y'] * RATIO + "px",
          "left": field['x'] * RATIO + "px",
          "width": field['w'] * RATIO + "px",
          "height": field['h'] * RATIO + "px",
        }}>
          {field['val'] === "n" ?
            <div className="style-n"></div>
          : field['val'] === "r" ?
            <div className="style-r"></div>
          : field['val'] === "o" ?
            <div className="style-o"></div>
          : field['val'] === "t" ?
            <div className="style-t"></div>
          : field['val'] === "i" ?
            <div className="style-i"></div>
          : field['val']}
        </span>
      ) : null}
      <span className="style-name">{item.name}</span>
      <span className="style-description">{item.description}</span>
      <span className="style-code">{item.id}</span>

      {/*

        {item.images && item.images.length > 0 ? item.images.map((image, i) => (
          <img data-target-image onClick={(e) => setTargetImage(e.target)} key={item.footer.code+"-"+i+"-image"} className="image refProductImage" src={image.src} data-src={image.src} style={{
            "top": image.y + "%",
            "left": image.x + "%",
            "width":  image.w + "%"
          }} alt="" />
        )) : null}
        {item.labelsPrimary && item.labelsPrimary.length > 0 ? item.labelsPrimary.map((text, i) => (
          <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} key={item.footer.code+"-"+i+"-previewLabelPrimary"} className="previewLabel previewLabelPrimary refLabelPrimary" style={{
            "top": text.y + "%",
            "left": text.x + "%",
            "width":  text.w + "%"
          }}>{text.text}</div>
        )) : null}
        {item.labelsSecondary1 && item.labelsSecondary1.length > 0 ? item.labelsSecondary1.map((text, i) => (
          <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} key={item.footer.code+"-"+i+"-previewLabelSecondary"} className="previewLabel previewLabelSecondary refLabelSecondary1" style={{
            "top": text.y + "%",
            "left": text.x + "%",
            "width":  text.w + "%"
          }}>{text.text}</div>
        )) : null}
        {item.labelsSecondary2 && item.labelsSecondary2.length > 0 ? item.labelsSecondary2.map((text, i) => (
          <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} key={item.footer.code+"-"+i+"-previewLabelSecondary"} className="previewLabel previewLabelSecondary refLabelSecondary2" style={{
            "top": text.y + "%",
            "left": text.x + "%",
            "width":  text.w + "%"
          }}>{text.text}</div>
        )) : null}
        {item.infoIcons && item.infoIcons.length > 0 ? item.infoIcons.map((image, i) => (
          <img data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} key={item.footer.code+"-"+i+"-imageinfo"} className="image refInfoIcon" src={image.src} data-src={image.src} style={{
            "top": image.y + "%",
            "left": image.x + "%",
            "width":  image.w + "%"
          }} alt="" />
        )) : null}
        {item.infoTexts && item.infoTexts.length > 0 ? item.infoTexts.map((text, i) => (
          <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} key={item.footer.code+"-"+i+"-infoText"} className="infoText refInfoText" style={{
            "top": text.y + "%",
            "left": text.x + "%"
          }}>{text.text}</div>
        )) : null}


      */}
    </div>
  );

}

export default TemplateOld;
