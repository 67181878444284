import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Button from './Button.js';
import A from './A.js';
import Link from './Link.js';

const Wrapper = styled.div`
  & > * {
    margin-bottom: 10px;
  }
`;

const Menu = () => {
  const { pathname } = useLocation();
  return (
    <Wrapper>
      <Link
        to={"/gem"}
        display="block"
      >
        <Button active={pathname === "/gem"}>GEM</Button>
      </Link>
      <Link
        to={"/drive"}
        display="block"
      >
        <Button active={pathname === "/drive"}>Importazione Drive</Button>
      </Link>
      <A
        href="https://docs.google.com/spreadsheets/d/1vB34440AI534jxx_rzE5OmOdMNcB9Rnqk8z5sfWflFo/edit?usp=sharing"
        target="_blank"
        display="block"
      >
        <Button>File di compilazione Drive</Button>
      </A>
    </Wrapper>
  )
}

export default Menu;
