import React from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';

import Homepage from './pages/Homepage.js';
// import Drive from './pages/Drive.js';
import Edit from './pages/Edit.js';
// import Archive from './pages/Archive.js';
import List from './pages/List.js';

const EditWrapper = (props) => {
  const params = useParams();
  return <Edit {...{...props, match: {params}} } />
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Homepage/>} />
        <Route exact path='/gem' element={<List type="gem" />} />
        <Route exact path='/drive' element={<List type="drive" />} />
        <Route exact path='/edit/:id' element={<EditWrapper/>} />
        <Route exact path='/archive' element={<List type="archived" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
