import React, {Component} from 'react';
import styled from 'styled-components';

import Layout from './../components/Layout.js';
import Button from './../components/Button.js';
import Link from './../components/Link.js';

// import TemplateEditable from './../components/template/TemplateEditable.js';
// import TemplateLayout from './../components/template/TemplateLayout.js';
import TemplateMulti from './../components/template/TemplateMulti.js';

import DatabaseService from '../services/DatabaseService';


const Bar = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 2px 16px #C1CCE838;
  padding: 15px 20px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const TemplateBlock  = styled.div`
  width: calc(889px + 20px);
  padding: 10px;
  background-color: #D2DFE0;
  border-radius: 4px;
  display: flex-inline;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 4px;

  & *{
    height: auto;
  }

`;

const TemplateWrapper  = styled.div`
   width: 889px;
  border-radius: 4px;
    overflow: visible;
`;

const TemplateControls  = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;
const TemplateControlsLeft  = styled.div`
  display: flex;
  & > * {
    margin-right: 10px;
  }
`;
const TemplateControlsRight  = styled.div`
  display: flex;
  & > * {
    margin-left: 10px;
  }
`;
const Saved  = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 14px;
`;

class Edit extends Component {

  constructor(props){
    super(props);
    this.jobsIndex = null;
    this.reference = null;
    this.state = {
      element: null,
      saved: false
    }
  }

  componentDidMount(){
    let that = this;
    DatabaseService.getArchive(this.props.match.params.id,"drive").then((res) => {
      // console.log(res);
      that.setState({
        element: res
      });
    });
  }


  getLayout(nodeContainer, element){
    let nodeTemplate = nodeContainer.getElementsByClassName("banner")[0];
    let layout = {
      images: [],
      labelsPrimary: [],
      labelsSecondary1: [],
      labelsSecondary2: [],
      labelsNotes: [],
      infoIcons: [],
      infoTexts: []
    };
    
    let templateOffsetX = nodeTemplate.getBoundingClientRect().left;
    let templateOffsetY = nodeTemplate.getBoundingClientRect().top;
    let templateWidth = nodeTemplate.getBoundingClientRect().width;
    let templateHeight = (element.size === "Auto" ? 220 : 328) - nodeTemplate.getElementsByClassName("descriptionTable")[0].getBoundingClientRect().height - 12;

    let nodesProductImage = nodeTemplate.getElementsByClassName("refProductImage");
    for (let nodeProductImage of nodesProductImage) {
      let objProductImage = {
        src: nodeProductImage.src,
        x: (nodeProductImage.getBoundingClientRect().left - templateOffsetX) * 100 / templateWidth,
        y: (nodeProductImage.getBoundingClientRect().top - templateOffsetY) * 100 / templateHeight,
        w: nodeProductImage.getBoundingClientRect().width * 100 / templateWidth,
        h: nodeProductImage.getBoundingClientRect().height * 100 / templateHeight
      };
      layout.images.push(objProductImage);
    }

    let nodesLabelPrimary = nodeTemplate.getElementsByClassName("refLabelPrimary");
    for (let nodeLabelPrimary of nodesLabelPrimary) {
      let objLabelPrimary = {
        x: (nodeLabelPrimary.getBoundingClientRect().left - templateOffsetX) * 100 / templateWidth,
        y: (nodeLabelPrimary.getBoundingClientRect().top - templateOffsetY) * 100 / templateHeight,
        w: nodeLabelPrimary.getBoundingClientRect().width * 100 / templateWidth,
        text: nodeLabelPrimary.innerText
        // w: nodeProductImage.getBoundingClientRect().width * 100 / templateWidth,
        // h: nodeProductImage.getBoundingClientRect().height * 100 / templateHeight
      };
      layout.labelsPrimary.push(objLabelPrimary);
    }

    let nodesLabelSecondary1 = nodeTemplate.getElementsByClassName("refLabelSecondary1");
    for (let nodeLabelSecondary1 of nodesLabelSecondary1) {
      let objLabelSecondary1 = {
        x: (nodeLabelSecondary1.getBoundingClientRect().left - templateOffsetX) * 100 / templateWidth,
        y: (nodeLabelSecondary1.getBoundingClientRect().top - templateOffsetY) * 100 / templateHeight,
        w: nodeLabelSecondary1.getBoundingClientRect().width * 100 / templateWidth,
        text: nodeLabelSecondary1.innerText
      };
      layout.labelsSecondary1.push(objLabelSecondary1);
    }

    let nodesLabelSecondary2 = nodeTemplate.getElementsByClassName("refLabelSecondary2");
    for (let nodeLabelSecondary2 of nodesLabelSecondary2) {
      let objLabelSecondary2 = {
        x: (nodeLabelSecondary2.getBoundingClientRect().left - templateOffsetX) * 100 / templateWidth,
        y: (nodeLabelSecondary2.getBoundingClientRect().top - templateOffsetY) * 100 / templateHeight,
        w: nodeLabelSecondary2.getBoundingClientRect().width * 100 / templateWidth,
        text: nodeLabelSecondary2.innerText
      };
      layout.labelsSecondary2.push(objLabelSecondary2);
    }

    let nodesLabelNotes = nodeTemplate.getElementsByClassName("refLabelNotes");
    for (let nodeLabelNotes of nodesLabelNotes) {
      let objLabelNotes = {
        x: (nodeLabelNotes.getBoundingClientRect().left - templateOffsetX) * 100 / templateWidth,
        y: (nodeLabelNotes.getBoundingClientRect().top - templateOffsetY) * 100 / templateHeight,
        w: nodeLabelNotes.getBoundingClientRect().width * 100 / templateWidth,
        text: nodeLabelNotes.innerText
      };
      layout.labelsNotes.push(objLabelNotes);
    }

    let nodesInfoText = nodeTemplate.getElementsByClassName("refInfoText");
    for (let nodeInfoText of nodesInfoText) {
      let objInfoText = {
        x: (nodeInfoText.getBoundingClientRect().left - templateOffsetX) * 100 / templateWidth,
        y: (nodeInfoText.getBoundingClientRect().top - templateOffsetY) * 100 / templateHeight,
        w: nodeInfoText.getBoundingClientRect().width * 100 / templateWidth,
        text: nodeInfoText.innerText
      };
      layout.infoTexts.push(objInfoText);
    }

    let nodesInfoIcon = nodeTemplate.getElementsByClassName("refInfoIcon");
    for (let nodeInfoIcon of nodesInfoIcon) {
      let objInfoIcon = {
        x: (nodeInfoIcon.getBoundingClientRect().left - templateOffsetX) * 100 / templateWidth,
        y: (nodeInfoIcon.getBoundingClientRect().top - templateOffsetY) * 100 / templateHeight,
        w: nodeInfoIcon.getBoundingClientRect().width * 100 / templateWidth,
        src: nodeInfoIcon.attributes["data-src"].value
      };
      layout.infoIcons.push(objInfoIcon);
    }

  // console.log(layout);
    return layout;
  }
  getReLayout(nodeContainer, element){
    let nodeTemplate = nodeContainer.getElementsByClassName("banner")[0];
    let layout = {
      images: [],
      labelsPrimary: [],
      labelsSecondary1: [],
      labelsSecondary2: [],
      labelsNotes: [],
      infoIcons: [],
      infoTexts: []
    };
    let templateOffsetX = nodeTemplate.getBoundingClientRect().left;
    let templateOffsetY = nodeTemplate.getBoundingClientRect().top;
    let templateWidth = nodeTemplate.getBoundingClientRect().width;
    let templateHeight = (element.size === "Auto" ? 220 : 328) - nodeTemplate.getElementsByClassName("layoutFooter")[0].getBoundingClientRect().height - 12;

    let nodesProductImage = nodeTemplate.getElementsByClassName("refProductImage");
    for (let nodeProductImage of nodesProductImage) {
      let objProductImage = {
        src: nodeProductImage.src,
        x: (nodeProductImage.getBoundingClientRect().left - templateOffsetX) * 100 / templateWidth,
        y: (nodeProductImage.getBoundingClientRect().top - templateOffsetY) * 100 / templateHeight,
        w: nodeProductImage.getBoundingClientRect().width * 100 / templateWidth,
        h: nodeProductImage.getBoundingClientRect().height * 100 / templateHeight
      };
      layout.images.push(objProductImage);
    }

    let nodesLabelPrimary = nodeTemplate.getElementsByClassName("refLabelPrimary");
    for (let nodeLabelPrimary of nodesLabelPrimary) {
      let objLabelPrimary = {
        x: (nodeLabelPrimary.getBoundingClientRect().left - templateOffsetX) * 100 / templateWidth,
        y: (nodeLabelPrimary.getBoundingClientRect().top - templateOffsetY) * 100 / templateHeight,
        w: nodeLabelPrimary.getBoundingClientRect().width * 100 / templateWidth,
        text: nodeLabelPrimary.innerText
        // w: nodeProductImage.getBoundingClientRect().width * 100 / templateWidth,
        // h: nodeProductImage.getBoundingClientRect().height * 100 / templateHeight
      };
      layout.labelsPrimary.push(objLabelPrimary);
    }

    let nodesLabelSecondary1 = nodeTemplate.getElementsByClassName("refLabelSecondary1");
    for (let nodeLabelSecondary1 of nodesLabelSecondary1) {
      let objLabelSecondary1 = {
        x: (nodeLabelSecondary1.getBoundingClientRect().left - templateOffsetX) * 100 / templateWidth,
        y: (nodeLabelSecondary1.getBoundingClientRect().top - templateOffsetY) * 100 / templateHeight,
        w: nodeLabelSecondary1.getBoundingClientRect().width * 100 / templateWidth,
        text: nodeLabelSecondary1.innerText
      };
      layout.labelsSecondary1.push(objLabelSecondary1);
    }

    let nodesLabelSecondary2 = nodeTemplate.getElementsByClassName("refLabelSecondary2");
    for (let nodeLabelSecondary2 of nodesLabelSecondary2) {
      let objLabelSecondary2 = {
        x: (nodeLabelSecondary2.getBoundingClientRect().left - templateOffsetX) * 100 / templateWidth,
        y: (nodeLabelSecondary2.getBoundingClientRect().top - templateOffsetY) * 100 / templateHeight,
        w: nodeLabelSecondary2.getBoundingClientRect().width * 100 / templateWidth,
        text: nodeLabelSecondary2.innerText
      };
      layout.labelsSecondary2.push(objLabelSecondary2);
    }

    let nodesLabelNotes = nodeTemplate.getElementsByClassName("refLabelNotes");
    for (let nodeLabelNotes of nodesLabelNotes) {
      let objLabelNotes = {
        x: (nodeLabelNotes.getBoundingClientRect().left - templateOffsetX) * 100 / templateWidth,
        y: (nodeLabelNotes.getBoundingClientRect().top - templateOffsetY) * 100 / templateHeight,
        w: nodeLabelNotes.getBoundingClientRect().width * 100 / templateWidth,
        text: nodeLabelNotes.innerText
      };
      layout.labelsNotes.push(objLabelNotes);
    }

    let nodesInfoText = nodeTemplate.getElementsByClassName("refInfoText");
    for (let nodeInfoText of nodesInfoText) {
      let objInfoText = {
        x: (nodeInfoText.getBoundingClientRect().left - templateOffsetX) * 100 / templateWidth,
        y: (nodeInfoText.getBoundingClientRect().top - templateOffsetY) * 100 / templateHeight,
        w: nodeInfoText.getBoundingClientRect().width * 100 / templateWidth,
        text: nodeInfoText.innerText
      };
      layout.infoTexts.push(objInfoText);
    }

    let nodesInfoIcon = nodeTemplate.getElementsByClassName("refInfoIcon");
    for (let nodeInfoIcon of nodesInfoIcon) {
      let objInfoIcon = {
        x: (nodeInfoIcon.getBoundingClientRect().left - templateOffsetX) * 100 / templateWidth,
        y: (nodeInfoIcon.getBoundingClientRect().top - templateOffsetY) * 100 / templateHeight,
        w: nodeInfoIcon.getBoundingClientRect().width * 100 / templateWidth,
        src: nodeInfoIcon.attributes["data-src"].value
      };
      layout.infoIcons.push(objInfoIcon);
    }

  // console.log(layout);
    return layout;
  }

  reset(){
    let that = this;
    let id = this.props.match.params.id; //parseInt(this.props.match.params.id,10);

    DatabaseService.updateArchive(id,"drive",{
      "status": "new",
      "editedJson": null

    }).then((res) => {
      that.setState({
        element: res
      });
    });
  }

  confirm(){
    let that = this;
    let id = this.props.match.params.id; //parseInt(this.props.match.params.id,10);
    let layout = this.getLayout(this.reference, this.state.element);

    DatabaseService.updateArchive(id,"drive",{
      "status": "confirmed",
      "editedJson": layout

    }).then((res) => {
      that.setState({
        element: res
      });
    });
  }

  reconfirm(){
    let that = this;
    let id = this.props.match.params.id; //parseInt(this.props.match.params.id,10);
    let layout = this.getReLayout(this.reference, this.state.element);

    DatabaseService.updateArchive(id,"drive",{
      "status": "confirmed",
      "editedJson": layout

    }).then((res) => {
      that.setState({
        element: res
      });
    });
  }

  render(){
    return (
      <Layout>
        <Bar>
          <Link
            to={"/drive"}
            display="block"
          >
            <Button display="inline-block" variant="white">Indietro</Button>
          </Link>
        </Bar>

        {this.state.element ?
          <TemplateBlock>
            <TemplateControls>
              <TemplateControlsLeft>
                {this.state.saved ?
                  <Saved>Saved!</Saved>
                : null }
              </TemplateControlsLeft>
              <TemplateControlsRight>
                {this.state.element.status === "new" ?
                  <div onClick={() => this.confirm()}>
                    <Button display="inline-block" variant="blue">Conferma</Button>
                  </div>
                :
                  <>
                    <div onClick={() => this.reset()}>
                      <Button display="inline-block" variant="red">Reset</Button>
                    </div>                  
                    <div onClick={() => this.reconfirm()}>
                      <Button display="inline-block" variant="blue">Ri-Conferma</Button>
                    </div>
                  </>
                }
              </TemplateControlsRight>
            </TemplateControls>
            <TemplateWrapper ref={(el) => this.reference = el}>
              <TemplateMulti editable={true} data={this.state.element ? JSON.stringify(this.state.element) : null} />
            </TemplateWrapper>
          </TemplateBlock>
        : null}
      </Layout>
    )
  }
}

export default Edit;
