import React from 'react';
import styled from 'styled-components';

import Menu from './Menu.js';
import Link from './Link.js';

import LogoImage from './../assets/logo.svg';

const Wrapper = styled.div`
  font-family: 'Roboto', sans-serif;

  display: flex;
  flex-direction: row;

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  background-color: #EDF2F7;
`;
const Sidebar = styled.div`
  width: 250px;
  height: 100%;

  background-color: #ffffff;
  border-right: 1px solid #E2E2E2;

  flex-grow: 0;
  flex-shrink: 0;

  padding: 20px;
`;
const Body = styled.div`
  height: 100%;

  flex-grow: 1;
  flex-shrink: 1;

  display: flex;
  flex-direction: column;
`;
const Topbar = styled.div`
  width: 100%;
  height: 60px;

  background-color: #ffffff;
  box-shadow: 0px 2px 16px #C1CCE838;

  flex-grow: 0;
  flex-shrink: 0;
`;
const Content = styled.div`
  height: 100%;

  flex-grow: 1;
  flex-shrink: 1;

  overflow-x: hidden;
  overflow-y: auto;

  padding: 20px 50px;
`;


const Logo = styled.img`
  width: 100%;
  margin-bottom: 30px;
`;

const Layout = ({children}) => {
  return (
    <Wrapper>
      <Sidebar>
        <Link
          to={"/"}
          display="block"
        >
          <Logo src={LogoImage} />
        </Link>
        <Menu/>
      </Sidebar>
      <Body>
        <Topbar></Topbar>
        <Content>
          {children}
        </Content>
      </Body>
    </Wrapper>
  )
}

export default Layout;
