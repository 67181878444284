import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: ${props=>props.display};
  padding: 8px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;

  ${props=>props.variant === "default" ?
    props.active ? `
      border: 1px solid #009DE2;
      background-color: #009DE2;
      color: #ffffff;
    `:`
      border: 1px solid #D8D8D8;
      background-color: #ffffff;
      color: #000000;
    `
  : ""}

  ${props=>props.variant === "blue" ?
    props.active ? `
      border: 1px solid #009DE2;
      background-color: #009DE2;
      color: #ffffff;
    `:`
      border: 1px solid #009DE2;
      background-color: #009DE2;
      color: #ffffff;
    `
  : ""}

  ${props=>props.variant === "green" ?
    props.active ? `
      border: 1px solid #00A506;
      background-color: #00A506;
      color: #ffffff;
    `:`
      border: 1px solid #00A506;
      background-color: #00A506;
      color: #ffffff;
    `
  : ""}

  ${props=>props.variant === "red" ?
    props.active ? `
      border: 1px solid #FF0000;
      background-color: #FF0000;
      color: #ffffff;
    `:`
      border: 1px solid #FF0000;
      background-color: #FF0000;
      color: #ffffff;
    `
  : ""}

  ${props=>props.variant === "white" ?
    props.active ? `
      border: 1px solid #D3D3D3;
      background-color: #ffffff;
      color: #000000;
    `:`
      border: 1px solid #D3D3D3;
      background-color: #ffffff;
      color: #000000;
    `
  : ""}

  ${props=>props.variant === "green-o" ?
    props.active ? `
      border: 3px solid #00A506;
      background-color: transparent;
      color: #00A506;
    `:`
      border: 3px solid #00A506;
      background-color: transparent;
      color: #00A506;
    `
  : ""}

  ${props=>props.variant === "red-o" ?
    props.active ? `
      border: 3px solid #FF0000;
      background-color: transparent;
      color: #FF0000;
    `:`
      border: 3px solid #FF0000;
      background-color: transparent;
      color: #FF0000;
    `
  : ""}

  ${props=>props.variant === "white-o" ?
    props.active ? `
      border: 3px solid #ffffff;
      background-color: transparent;
      color: #ffffff;
    `:`
      border: 3px solid #ffffff;
      background-color: transparent;
      color: #ffffff;
    `
  : ""}
`;

const Button = ({variant = "default", display, active, children}) => {
  return (
    <Wrapper variant={variant} display={display} active={active}>
      {children}
    </Wrapper>
  )
}

export default Button;
