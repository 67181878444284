import React from 'react';
// import styled from 'styled-components';

import Layout from './../components/Layout.js';


const Homepage = () => {
  return (
    <Layout>
      Home
    </Layout>
  )
}

export default Homepage;
