import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';


const Wrapper = styled(RouterLink)`
  display: ${props => props.display};
  text-decoration: none;
  color: inherit;
`;

const Link = ({to, display, children}) => {
  return (
    <Wrapper to={to} display={display}>
      {children}
    </Wrapper>
  )
}

export default Link;
