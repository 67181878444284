import React, { useState, useEffect } from 'react';
import Moveable from "react-moveable";

import DatabaseService from '../../services/DatabaseService';


import './template.css';

const WIDTH = 889 - 72;
const RATIO = 1; //test
const LABEL_HEIGHT = 100;
const NOIMAGE_WIDTH = 500;


const TemplateMulti = ({data, editable = false}) => {

  const [targetImage, setTargetImage] = useState(null);
  const [targetItem, setTargetItem] = useState(null);
  const [item, setItem] = useState(JSON.parse(data));
  const [countInforows, setCountInforows] = useState(0);
  // const [imagesInfo, setImagesInfo] = useState(null);
  const [loaded, setLoaded] = useState(false);
  // const [imagesLength, setImagesLength] = useState(0);
  const [widthScale, setWidthScale] = useState(1);
  const [globalOffset, setGlobalOffset] = useState(0);

  const eventClick = (event) => {
    if(!event.target.hasAttribute("data-target-image")){
      setTargetImage(null);
    }
    if(!event.target.hasAttribute("data-target-item") && !event.target.closest("[data-target-item]")){
      setTargetItem(null);
    }
  };
  useEffect(() => {
    window.addEventListener('click', eventClick);
    return () => {
      window.removeEventListener('click', eventClick);
    };
  }, []);

  useEffect(() => {
    setLoaded(false);
    setItem(JSON.parse(data));
  }, [data]);


  useEffect(() => {
    if(!loaded){
      let countInforows = 0;
      let newData = {...item};
      let imageIds = [];
      // let imagesWidth = 0;
      if(item.importJson){
        item.importJson.forEach((column, i) => {
          column.prods.forEach((product, j) => {
            if(product.imageId){
              imageIds.push(product.imageId);
            }
          });
          // console.log(column.rows);
          if(column.rows && column.rows.length > countInforows){
            countInforows = column.rows.length;
          }
        });
      }

      if(imageIds.length > 0){
        Promise.all(imageIds.map((id) => DatabaseService.getProduct(id))).then(function(results){
          let offset = 0;
          let globalOffset = 0;
          let colLengths = 0;

          newData.importJson.forEach((column, i) => {
            let colLength = 0
            column.prods.forEach((product, j) => {
              product.imageData = results.filter(item => item.id === product.imageId)[0];
              if(product.imageData && product.imageData.image.width > colLength){
                colLength = product.imageData.image.width;
              }else{
                colLength = NOIMAGE_WIDTH;
              }
            });
            colLengths+=colLength;
          });
          let widthScale = 1;
          if(colLengths > WIDTH){
            widthScale = (WIDTH / (colLengths*RATIO));
          }

          // console.log(colLengths * widthScale);
          // console.log(widthScale);

          newData.importJson.forEach((column, i) => {
            column.prods.forEach((product, j) => {
              // console.log(product);
              // console.log(product.imageData.image.width);
              // console.log(widthScale);
              if(product.imageData){
                // product.imageData.image.width = product.imageData.image.width * widthScale;
              }else{
                product.columnWidth = NOIMAGE_WIDTH;
              }
              // product.imageData.image.width = product.imageData.image.width * widthScale;
            });


            // let maxImagesWidth = 0;
  // console.log(i);
  // console.log(column.prods.length);
  // console.log("---");
            if(column.prods.length > 1){
              if(column.prods.length % 2 === 0){
                let iAvg1 = (column.prods.length / 2) -1;
                let iAvg2 = (column.prods.length / 2);
                //
                //   console.log("entro");
                // console.log("iAvg1: " + iAvg1);
                // console.log("iAvg2: " + iAvg2);

                let anchor1L = 0;
                let anchor2L = 0;
                let anchor1R = 0;
                let anchor2R = 0;
  //funziona solo con 2
                if(column.prods[iAvg1].imageData && column.prods[iAvg2].imageData){
                  if(i !== 0){
                    anchor1L = offset - (column.prods[iAvg1].imageData.anchors.left.y * widthScale);
                    anchor2L = anchor1L + ((column.prods[iAvg1].imageData.image.height * widthScale) - (column.prods[iAvg1].imageData.anchors.left.y * widthScale) + (column.prods[iAvg2].imageData.anchors.left.y * widthScale) + LABEL_HEIGHT/RATIO);
                  }
                  offset = anchor1L - ((anchor2L - anchor1L)/2);

                  // if(i !== 0){
                  //   anchor1L = column.prods[iAvg1].imageData.anchors.left.y;
                  //   anchor2L = column.prods[iAvg1].imageData.image.height + column.prods[iAvg2].imageData.anchors.left.y + LABEL_HEIGHT*RATIO;
                  // }
                  // offset -= ((anchor2L - anchor1L)/2);

                  column.prods[iAvg1].imageOffset = offset;
                  column.prods[iAvg1].labelOffset = offset + (column.prods[iAvg1].imageData.image.height * widthScale);
                  column.prods[iAvg2].imageOffset = null;
                  column.prods[iAvg2].labelOffset = offset + (column.prods[iAvg1].imageData.image.height * widthScale) + (column.prods[iAvg2].imageData.image.height * widthScale) + LABEL_HEIGHT/RATIO;
                  if(offset < 0 && offset < -globalOffset){
                    globalOffset = -offset;
                  }

                  anchor1R = offset + (column.prods[iAvg1].imageData.anchors.right.y * widthScale);
                  anchor2R = anchor1R + ((column.prods[iAvg1].imageData.image.height * widthScale) - (column.prods[iAvg1].imageData.anchors.right.y * widthScale) + (column.prods[iAvg2].imageData.anchors.right.y * widthScale) + LABEL_HEIGHT/RATIO);

                  offset = anchor2R - ((anchor2R - anchor1R)/2);

                }



              }else{
                if(column.prods[0].imageData && column.prods[1].imageData && column.prods[2].imageData){
                  if(i !== 0){
                    offset -= (column.prods[1].imageData.anchors.left.y * widthScale) + ((column.prods[0].imageData.image.height * widthScale) + LABEL_HEIGHT/RATIO);
                  }
                  column.prods[0].imageOffset = offset;
                  column.prods[0].labelOffset = offset + (column.prods[0].imageData.image.height * widthScale);
                  column.prods[1].imageOffset = null;
                  column.prods[1].labelOffset = offset + (column.prods[0].imageData.image.height * widthScale) + (column.prods[1].imageData.image.height * widthScale) + LABEL_HEIGHT/RATIO;
                  column.prods[2].imageOffset = null;
                  column.prods[2].labelOffset = offset + (column.prods[0].imageData.image.height * widthScale) + (column.prods[1].imageData.image.height * widthScale) + (column.prods[2].imageData.image.height * widthScale) + LABEL_HEIGHT/RATIO*2;
                  if(offset < 0 && offset < -globalOffset){
                    globalOffset = -offset;
                  }
                  offset += (column.prods[1].imageData.anchors.right.y * widthScale) + ((column.prods[0].imageData.image.height * widthScale) + LABEL_HEIGHT/RATIO);

                }


              }
            }else if(column.prods.length === 1){
              if(column.prods[0].imageData){
                if(i !== 0){
                  offset -= (column.prods[0].imageData.anchors.left.y * widthScale)
                }
                column.prods[0].imageOffset = offset;
                column.prods[0].labelOffset = offset + (column.prods[0].imageData.image.height * widthScale);
                if(offset < 0 && offset < -globalOffset){
                  globalOffset = -offset;
                }
                offset += (column.prods[0].imageData.anchors.right.y * widthScale);
              }
            }

            // imagesWidth += maxImagesWidth;
          });
          setItem(newData);
          setLoaded(true);
          // setImagesLength(imagesWidth);
          setGlobalOffset(globalOffset);
          setWidthScale(widthScale);
        });
      }else{
        // setCountInforows(0);
        setItem(newData);
        setLoaded(true);
      }
      setCountInforows(countInforows);
    }
  }, [item, loaded]);


  // console.log("render");
  // console.log(item.status);
  // console.log(item);
  if(loaded){
    if(item.status === "confirmed"){
      return (
        <div className={"banner size"+item.size}>
          {!editable ?
            <div style={{zIndex: "999", position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%"}}></div>
          : null }
          {loaded && item.editedJson ?
            <>
              <div className="layoutArea">
                {item.editedJson.images && item.editedJson.images.length > 0 ? item.editedJson.images.map((image, i) => (
                  <img data-target-image onClick={(e) => setTargetImage(e.target)} key={item.id+"-"+i+"-image"} className="image refProductImage" src={image.src} data-src={image.src} style={{
                    "top": image.y + "%",
                    "left": image.x + "%",
                    "width":  image.w + "%"
                  }} alt="" />
                )) : 
                  null
                }
                {item.editedJson.labelsPrimary && item.editedJson.labelsPrimary.length > 0 ? item.editedJson.labelsPrimary.map((text, i) => (
                  <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} key={item.id+"-"+i+"-previewLabelPrimary"} className="previewLabel previewLabelPrimary refLabelPrimary" style={{
                    "top": text.y + "%",
                    "left": text.x + "%",
                    "width":  text.w + "%"
                  }}>{text.text}</div>
                )) : null}
                {item.editedJson.labelsSecondary1 && item.editedJson.labelsSecondary1.length > 0 ? item.editedJson.labelsSecondary1.map((text, i) => (
                  <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} key={item.id+"-"+i+"-previewLabelSecondary"} className="previewLabel previewLabelSecondary refLabelSecondary1" style={{
                    "top": text.y + "%",
                    "left": text.x + "%",
                    "width":  text.w + "%"
                  }}>{text.text}</div>
                )) : null}
                {item.editedJson.labelsSecondary2 && item.editedJson.labelsSecondary2.length > 0 ? item.editedJson.labelsSecondary2.map((text, i) => (
                  <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} key={item.id+"-"+i+"-previewLabelSecondary"} className="previewLabel previewLabelSecondary refLabelSecondary2" style={{
                    "top": text.y + "%",
                    "left": text.x + "%",
                    "width":  text.w + "%"
                  }}>{text.text}</div>
                )) : null}
                {item.editedJson.labelsNotes && item.editedJson.labelsNotes.length > 0 ? item.editedJson.labelsNotes.map((text, i) => (
                  <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} key={item.id+"-"+i+"-previewLabelNotes"} className="previewLabel previewLabelNotes refLabelNotes" style={{
                    "top": text.y + "%",
                    "left": text.x + "%",
                    "width":  text.w + "%"
                  }}>{text.text}</div>
                )) : null}
                {item.editedJson.infoIcons && item.editedJson.infoIcons.length > 0 ? item.editedJson.infoIcons.map((image, i) => (
                  <img data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} key={item.id+"-"+i+"-imageinfo"} className="image refInfoIcon" src={image.src} data-src={image.src} style={{
                    "top": image.y + "%",
                    "left": image.x + "%",
                    "width":  image.w + "%"
                  }} alt="" />
                )) : null}
                {item.editedJson.infoTexts && item.editedJson.infoTexts.length > 0 ? item.editedJson.infoTexts.map((text, i) => (
                  <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} key={item.id+"-"+i+"-infoText"} className="infoText refInfoText" style={{
                    "top": text.y + "%",
                    "left": text.x + "%"
                  }}>{text.text}</div>
                )) : null}
              </div>
              <table className="layoutFooter">
                <tbody>
                  <tr className="descriptionRow">
                    <td>
                      <div className="description">
                        <div className="descriptionLeft">
                          <span className="descriptionSecondary">{item.info ? item.info : null}</span><br/>
                          <span className="descriptionPrimary">{item.name ? item.name : null}</span> <span className="descriptionSecondary">{item.details ? item.details : null}</span>
                        </div>
                        <div className="descriptionRight">
                          <span className="descriptionSecondary styleCode">{item.id ? item.id : null}</span><br/>
                          <span className="descriptionPrimary styleYear">{item.year ? item.year : null}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {editable ?
                <>
                  <Moveable
                   target={targetImage}
                   container={null}
                   origin={true}

                   /* Resize event edges */
                   edge={false}

                   /* draggable */
                   draggable={true}
                   throttleDrag={0}
                   onDragStart={({ target, clientX, clientY }) => {
                       // console.log("onDragStart", target);
                   }}
                   onDrag={({
                       target,
                       beforeDelta, beforeDist,
                       left, top,
                       right, bottom,
                       delta, dist,
                       transform,
                       clientX, clientY,
                   }: OnDrag) => {
                       // console.log("onDrag left, top", left, top);
                       // target.style.left = `${left}px`;
                       // target.style.top = `${top}px`;
                       // console.log("onDrag translate", dist);
                       target.style.transform = transform;
                   }}
                   onDragEnd={({ target, isDrag, clientX, clientY }) => {
                       // console.log("onDragEnd", target, isDrag);
                   }}

                   /* When resize or scale, keeps a ratio of the width, height. */
                   keepRatio={true}

                   /* scalable */
                   /* Only one of resizable, scalable, warpable can be used. */
                   scalable={true}
                   throttleScale={0}
                   onScaleStart={({ target, clientX, clientY }) => {
                       // console.log("onScaleStart", target);
                   }}
                   onScale={({
                       target, scale, dist, delta, transform,
                       clientX, clientY,
                   }: OnScale) => {
                       // console.log("onScale scale", scale);
                       target.style.transform = transform;
                   }}
                   onScaleEnd={({ target, isDrag, clientX, clientY }) => {
                       // console.log("onScaleEnd", target, isDrag);
                   }}

                 />
                 <Moveable
                  target={targetItem}
                  container={null}
                  origin={true}

                  /* Resize event edges */
                  edge={false}

                  /* draggable */
                  draggable={true}
                  throttleDrag={0}
                  onDragStart={({ target, clientX, clientY }) => {
                      // console.log("onDragStart", target);
                  }}
                  onDrag={({
                      target,
                      beforeDelta, beforeDist,
                      left, top,
                      right, bottom,
                      delta, dist,
                      transform,
                      clientX, clientY,
                  }: OnDrag) => {
                      // console.log("onDrag left, top", left, top);
                      // target.style.left = `${left}px`;
                      // target.style.top = `${top}px`;
                      // console.log("onDrag translate", dist);
                      target.style.transform = transform;
                  }}
                  onDragEnd={({ target, isDrag, clientX, clientY }) => {
                      // console.log("onDragEnd", target, isDrag);
                  }}

                  /* When resize or scale, keeps a ratio of the width, height. */
                  keepRatio={true}


                />
              </>
            : null }
            </>
          : null }
        </div>
      );
    }else{
      return (
        <div className={"banner size"+item.size}>
          {!editable ?
            <div style={{zIndex: "999", position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%"}}></div>
          : null }

          <table className="imageTable">
            <tbody>
              {[...Array(countInforows)].map((x, iRow) =>
                <tr className="infoRow" key={item.code+"-"+iRow}>
                  {item.importJson ? item.importJson.map((column, iCol) => (
                    <td key={item.code+"-"+iCol}>
                      {column.rows.length > iRow ?
                        <div className="info">
                          <div className={"infoElement"} data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))}>
                            <div className="infoIcon refInfoIcon" data-src={"https://cdn.ozwol.cloud/7CF7B37A3E/icons/"+column.rows[iRow].icon+".png"} style={{"backgroundImage": "url('https://cdn.ozwol.cloud/7CF7B37A3E/icons/"+column.rows[iRow].icon+".png')"}}></div>
                            <div className="infoText refInfoText">
                              {column.rows[iRow].text}
                            </div>
                          </div>
                        </div>
                      : null }
                    </td>
                  )) : null}
                </tr>
              )}
              <tr className="previewRow">
                {item.importJson ? item.importJson.map((column, iCol) => (
                  <td key={item.code+"-"+iCol}>
                    {column.prods ? column.prods.map((product, iProd) => (
                      <React.Fragment key={item.code+"-"+iCol+"-"+iProd}>
                        {product.imageData ?
                          <React.Fragment>
                            {product.code1 || product.code2 || product.code3 || product.code4 || product.description || product.notes ? 
                              <div className="previewLabel" style={{"top": ((globalOffset*RATIO)+(product.labelOffset*RATIO)+10)+"px"}}>
                                <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} style={{"display": "inline-block"}}>
                                  <div className="previewLabelPrimary refLabelPrimary">{product.code1}</div>
                                  <div className="previewLabelSecondary">
                                    {product.code2 ? <div className="refLabelSecondary1">{product.code2}</div> : null}
                                    {product.code3 ? <div className="refLabelSecondary1">{product.code3}</div> : null}
                                    {product.code4 ? <div className="refLabelSecondary1">{product.code4}</div> : null}
                                  </div>
                                </div>
                                <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} style={{"display": "inline-block"}}>
                                  <div className="previewLabelNotes refLabelNotes">{product.description}</div>
                                </div>
                                <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} style={{"display": "inline-block"}}>
                                  <div className="previewLabelNotes refLabelNotes">{product.notes}</div>
                                </div>
                              </div>
                            : null }
                            <img data-target-image onClick={(e) => setTargetImage(e.target)} className="refProductImage" id={"refProductImage-"+iCol+"-"+iProd} src={"https://cdn.ozwol.cloud/7CF7B37A3E/products/" + product.imageData.image.fileName} style={{"marginTop": (product.imageOffset !== null ? ((globalOffset*RATIO)+(product.imageOffset*RATIO)) : LABEL_HEIGHT) + "px", "width": (product.imageData.image.width*RATIO*widthScale)+"px" }} alt=""/><br/>
                          </React.Fragment>
                        :
                          <React.Fragment>
                            {product.code1 || product.code2 || product.code3 || product.code4 || product.description || product.notes ? 
                              <div className="previewLabel previewLabelIndependent">
                                <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} style={{"display": "inline-block"}}>
                                  <div className="previewLabelPrimary refLabelPrimary">{product.code1}</div>
                                  <div className="previewLabelSecondary">
                                    {product.code2 ? <div className=" refLabelSecondary1">{product.code2}</div> : null}
                                    {product.code3 ? <div className="refLabelSecondary1">{product.code3}</div> : null}
                                    {product.code4 ? <div className="refLabelSecondary1">{product.code4}</div> : null}
                                  </div>
                                </div>
                                <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} style={{"display": "inline-block"}}>
                                  <div className="previewLabelNotes refLabelNotes">{product.description}</div>
                                </div>
                                <div data-target-item onClick={(e) => setTargetItem(e.target.closest("[data-target-item]"))} style={{"display": "inline-block"}}>
                                  <div className="previewLabelNotes refLabelNotes">{product.notes}</div>
                                </div>
                              </div>
                            : null }
                            {product.imageData !== false ?
                              <>
                                <div  className="refProductImage" id={"refProductImage-"+iCol+"-"+iProd} src={"https://cdn.ozwol.cloud/ozwol/ui-icons/x.svg"} style={{"width": (product.columnWidth*RATIO*widthScale)+"px", "height": "1px", "background": "transparent" }} alt=""></div>
                              </>
                            : null }
                          </React.Fragment>
                        }
                      </React.Fragment>
                    )) : null}
                  </td>
                )) : null}
              </tr>
            </tbody>
          </table>
          <table className="descriptionTable">
            <tbody>
              <tr className="descriptionRow">
                <td colSpan={item.importJson ? item.importJson.length : 1}>
                  <div className="description">
                    <div className="descriptionLeft">
                      <span className="descriptionSecondary">{item.info ? item.info : null}</span><br/>
                      <span className="descriptionPrimary">{item.name ? item.name : null}</span> <span className="descriptionSecondary">{item.details ? item.details : null}</span>
                    </div>
                    <div className="descriptionRight">
                      <span className="descriptionSecondary styleCode">{item.id ? item.id : null}</span><br/>
                      <span className="descriptionPrimary styleYear">{item.year ? item.year : null}</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {editable ?
            <>
              <Moveable
               target={targetImage}
               container={null}
               origin={true}

               /* Resize event edges */
               edge={false}

               /* draggable */
               draggable={true}
               throttleDrag={0}
               onDragStart={({ target, clientX, clientY }) => {
                   // console.log("onDragStart", target);
               }}
               onDrag={({
                   target,
                   beforeDelta, beforeDist,
                   left, top,
                   right, bottom,
                   delta, dist,
                   transform,
                   clientX, clientY,
               }: OnDrag) => {
                   // console.log("onDrag left, top", left, top);
                   // target.style.left = `${left}px`;
                   // target.style.top = `${top}px`;
                   // console.log("onDrag translate", dist);
                   target.style.transform = transform;
               }}
               onDragEnd={({ target, isDrag, clientX, clientY }) => {
                   // console.log("onDragEnd", target, isDrag);
               }}

               /* When resize or scale, keeps a ratio of the width, height. */
               keepRatio={true}

               /* scalable */
               /* Only one of resizable, scalable, warpable can be used. */
               scalable={true}
               throttleScale={0}
               onScaleStart={({ target, clientX, clientY }) => {
                   // console.log("onScaleStart", target);
               }}
               onScale={({
                   target, scale, dist, delta, transform,
                   clientX, clientY,
               }: OnScale) => {
                   // console.log("onScale scale", scale);
                   target.style.transform = transform;
               }}
               onScaleEnd={({ target, isDrag, clientX, clientY }) => {
                   // console.log("onScaleEnd", target, isDrag);
               }}

             />
             <Moveable
              target={targetItem}
              container={null}
              origin={true}

              /* Resize event edges */
              edge={false}

              /* draggable */
              draggable={true}
              throttleDrag={0}
              onDragStart={({ target, clientX, clientY }) => {
                  // console.log("onDragStart", target);
              }}
              onDrag={({
                  target,
                  beforeDelta, beforeDist,
                  left, top,
                  right, bottom,
                  delta, dist,
                  transform,
                  clientX, clientY,
              }: OnDrag) => {
                  // console.log("onDrag left, top", left, top);
                  // target.style.left = `${left}px`;
                  // target.style.top = `${top}px`;
                  // console.log("onDrag translate", dist);
                  target.style.transform = transform;
              }}
              onDragEnd={({ target, isDrag, clientX, clientY }) => {
                  // console.log("onDragEnd", target, isDrag);
              }}

              /* When resize or scale, keeps a ratio of the width, height. */
              keepRatio={true}


            />
          </>
        : null }
        </div>
      );
    }
  }else{
    return null;
  }

}

export default TemplateMulti;
