import Papa from 'papaparse';
import axios from 'axios';
import ApiCore from './ApiCore';

class DatabaseService extends ApiCore {

  getProduct(id) {
    return new Promise((resolve, reject) => {
      this.instance.post('getProduct', {
        "id": id
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  listBrands() {
    return new Promise((resolve, reject) => {
      this.instance.post('listBrands').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  listArchive(params = {}) {
    return new Promise((resolve, reject) => {
      this.instance.post('searchArchive', {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getArchive(id, type) {
    return new Promise((resolve, reject) => {
      this.instance.post('getArchive', {
        "id": id,
        "type": type
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  setArchive(id, type, params = {}) {
    return new Promise((resolve, reject) => {
      console.log(params);
      this.instance.post('setArchive', {
        "id": id,
        "type": type,
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  updateArchive(id, type, params = {}) {
    return new Promise((resolve, reject) => {
      this.instance.post('updateArchive', {
        "id": id,
        "type": type,
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  delArchive(id, type) {
    return new Promise((resolve, reject) => {
      this.instance.post('delArchive', {
        "id": id,
        "type": type
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }



  import(area){
    return new Promise((resolve, reject) => {
      switch(area){
        case "gem":
          resolve(this.importGemXls());
          break;
        case "drive":
          resolve(this.importDriveXls());
          break;
        default:
          break;
      }
    });    
  }

  importGemXls(){
    let that = this;
    return new Promise((resolve, reject) => {
      Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vQUccSWzIJcVyLe31cr4-7lYvJCY2Ar-Kk0v35B-KeqYBAp-wXOatFdfXhC3aXLu-Fz4qa5KyloZarI/pub?gid=44286074&single=true&output=csv', {
        download: true,
        header: true,
        delimiter: ',',
        dynamicTyping: false,
        complete: function(resultsMarche) {
          let csvMarche = resultsMarche.data;

          Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vQUccSWzIJcVyLe31cr4-7lYvJCY2Ar-Kk0v35B-KeqYBAp-wXOatFdfXhC3aXLu-Fz4qa5KyloZarI/pub?gid=308103934&single=true&output=csv', {
            download: true,
            header: true,
            delimiter: ',',
            dynamicTyping: false,
            complete: function(resultsModelli) {
              let csvModelli = resultsModelli.data;

              Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vQUccSWzIJcVyLe31cr4-7lYvJCY2Ar-Kk0v35B-KeqYBAp-wXOatFdfXhC3aXLu-Fz4qa5KyloZarI/pub?gid=1638327764&single=true&output=csv', {
                download: true,
                header: true,
                delimiter: ',',
                dynamicTyping: false,
                complete: function(resultsName) {
                  let csvList = resultsName.data;

                  Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vQUccSWzIJcVyLe31cr4-7lYvJCY2Ar-Kk0v35B-KeqYBAp-wXOatFdfXhC3aXLu-Fz4qa5KyloZarI/pub?gid=846295244&single=true&output=csv', {
                    download: true,
                    header: true,
                    delimiter: ',',
                    dynamicTyping: false,
                    complete: function(resultsData) {
                      let csvData = resultsData.data;

                      Promise.all(
                        csvList.map((list, index) => list['GEMI_IMGNR'] ? 
                          that.setArchive(list['GEMI_IMGNR'],"gem", {
                            "status": "new",
                            "name": list['GEMI_MONTAGEBEZ'],
                            "description": list['GEMI_ZUSATZBEZ'],
                            "brand": csvMarche.filter((item) => item['COD'] === (list['GEMI_IMGNR']).substring(0,4)).length > 0 ? csvMarche.filter((item) => item['COD'] === (list['GEMI_IMGNR']).substring(0,4))[0]['DESCRIPTION'] : null,
                            "model": csvModelli.filter((item) => item['COD'] === list['GEMI_IMGNR']).length > 0 ? csvModelli.filter((item) => item['COD'] === list['GEMI_IMGNR'])[0]['DESCRIPTION'] : null,
                            "importJson": csvData.filter((item, key) => item['GEMD_IMGNR'] === list['GEMI_IMGNR']).map(item => ({
                              "val": item["GEMD_BEZ"],
                              "type": item["GEMD_TYP"],
                              "x": item["GEMD_X1"],
                              "y": item["GEMD_Y1"],
                              "w": item["GEMD_W"],
                              "h": item["GEMD_H"],
                            })),
                            "fulltext_search": (
                                                list['GEMI_IMGNR']
                                                +"_"+
                                                list['GEMI_MONTAGEBEZ']
                                                +"_"+
                                                list['GEMI_ZUSATZBEZ']
                                                +"_"+
                                                (csvMarche.filter((item) => item['COD'] === (list['GEMI_IMGNR']).substring(0,4)).length > 0 ? csvMarche.filter((item) => item['COD'] === (list['GEMI_IMGNR']).substring(0,4))[0]['DESCRIPTION'] : "")
                                                +"_"+
                                                (csvModelli.filter((item) => item['COD'] === list['GEMI_IMGNR']).length > 0 ? csvModelli.filter((item) => item['COD'] === list['GEMI_IMGNR'])[0]['DESCRIPTION'] : "")
                                               ).toLowerCase()
                          
                          })
                        : null)
                      ).then(response => {
                        resolve();
                      }).catch(error => {
                        reject(error);
                      });
                    }
                  });

                }
              });
            }
          });
        }
      });
    });
  }

  importDriveXls(){
    let that = this;
    return new Promise((resolve, reject) => {
      Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vQbON2OGa7kDSwUNxzU7CHkhBxP9eCJPMu9FSczulZivzPP828wAFIm5NUu_LPy0OjLfRoUvMmYFNHj/pub?gid=0&single=true&output=csv', {
        download: true,
        header: true,
        delimiter: ',',
        dynamicTyping: false,
        complete: function(result) {
          let csvList = result.data;

          Promise.all(
            csvList.map((csv, index) => csv.code && index > 2 ? 
              that.setArchive(csv.code, "drive", that.importDriveXlsTemplate(csv))
            : null)
          ).then(response => {
            resolve();
          }).catch(error => {
            reject(error);
          });
        }
      });
    });
  }

  importDriveXlsTemplate(csv){

    let currentTemplate = {};
    let currentColumn = {};
    let currentRow = {};
    let currentProduct = {};

    currentTemplate = {};
    currentTemplate.importJson = [];

    currentTemplate.id = csv.code;
    currentTemplate.size = csv.size;
    currentTemplate.type = "drive";
    currentTemplate.status = "new";
    currentTemplate.name = csv.name;
    currentTemplate.brand = csv.brand;
    currentTemplate.info = csv.info;
    currentTemplate.details = csv.details;
    currentTemplate.year = csv.year;

    for(let iColumn = 1; iColumn<=4; iColumn++){
      currentColumn={};
      currentColumn.rows = [];
      currentColumn.prods = [];

      for(let iRow = 1; iRow<=3; iRow++){
        currentRow={};
        if(csv[iColumn+"-row-"+iRow+"-icon"] || csv[iColumn+"-row-"+iRow+"-text"]){
          currentRow.icon = csv[iColumn+"-row-"+iRow+"-icon"];
          currentRow.text = csv[iColumn+"-row-"+iRow+"-text"];
          currentColumn.rows.push(currentRow);
        }
      }

      for(let iProduct = 1; iProduct<=3; iProduct++){
        currentProduct={};
        if(csv[iColumn+"-prod-"+iProduct+"-image"] || csv[iColumn+"-prod-"+iProduct+"-code1"] || csv[iColumn+"-prod-"+iProduct+"-code2"] || csv[iColumn+"-prod-"+iProduct+"-description"]){
          currentProduct.imageId = csv[iColumn+"-prod-"+iProduct+"-image"]; //currentProduct.imageId = csv[iColumn+"-prod-"+iProduct+"-image"] === "" ? null : csv[iColumn+"-prod-"+iProduct+"-image"];
          currentProduct.code1 = csv[iColumn+"-prod-"+iProduct+"-code1"];
          currentProduct.code2 = csv[iColumn+"-prod-"+iProduct+"-code2"];
          currentProduct.code3 = csv[iColumn+"-prod-"+iProduct+"-code3"];
          currentProduct.code4 = csv[iColumn+"-prod-"+iProduct+"-code4"];
          currentProduct.notes = csv[iColumn+"-prod-"+iProduct+"-notes"];
          currentProduct.description = csv[iColumn+"-prod-"+iProduct+"-description"];
          currentColumn.prods.push(currentProduct);
        }
      }
      
      if(currentColumn.rows.length > 0 && currentColumn.prods.length === 0){
        currentProduct={};
        currentProduct.imageId = null;
        currentColumn.prods.push(currentProduct);
      }

      currentTemplate.fulltext_search = (
                                          csv.code
                                          +"_"+
                                          csv.name
                                          +"_"+
                                          csv.description
                                          +"_"+
                                          csv.brand
                                          +"_"+
                                          csv.model
                                        ).toLowerCase()
                          

      currentTemplate.importJson.push(currentColumn);
    }
    return currentTemplate;
  }

  async generateGem(id, height = "66.4") {
    let that = this;
    return new Promise(async (resolve, reject) => {
      let item = {};
      item = await that.getArchive(id, "gem");
      try {
        const result = await axios.post("https://api.ozwol.com/v1/composer", {
          "template": "imasaf-card-gem",
          "data": {
            "RATIO": 890/2253,
            "URL_CDN": "https://cdn.ozwol.cloud/7CF7B37A3E/cardeditor_bmp/",
            "item": item,
            "options": {
        			"page-width": "180",
        			"page-height": height,
        			"margin-bottom": "0",
        			"margin-top": "0",
        			"margin-left": "0",
        			"margin-right": "0"
        		},
          },
          "fileName": id+".pdf",
          "pathName": "pdf/",
          "format": "pdf",
          "publish": true, //true pubblica in cdn, false no (publishtocdn flag dell'insider)
          "forceRebuild": false
        }, {
          headers: {
            "Content-Type": "application/json",
            "x-project-id": "7CF7B37A3E",
            "x-api-key": "GbOWQcRNfg3hpmt01PG9o9nfMSjRhI6vh_0yhHOWpCXgH0xPfGO4NPtO8rEw8JJJc-jfdBOcuishjrJhQ6FOEw"
          }
        });
        resolve(result.data.url);
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    });
  }
  async generateDrive(id, height) {
    let that = this;
    return new Promise(async (resolve, reject) => {
      let item = {};
      item = await that.getArchive(id, "drive");
      try {
        const result = await axios.post("https://api.ozwol.com/v1/composer", {
          "template": "imasaf-card-drive",
          "data": {
            "URL_CDN": "https://cdn.ozwol.cloud/7CF7B37A3E/cardeditor_bmp/",
            "item": item,
            "options": {
        			"page-width": "180",
        			"page-height": height,
        			"margin-bottom": "0",
        			"margin-top": "0",
        			"margin-left": "0",
        			"margin-right": "0"
        		},
          },
          "fileName": id+".pdf",
          "pathName": "pdf/",
          "format": "pdf",
          "publish": true, //true pubblica in cdn, false no (publishtocdn flag dell'insider)
          "forceRebuild": false
        }, {
          headers: {
            "Content-Type": "application/json",
            "x-project-id": "7CF7B37A3E",
            "x-api-key": "GbOWQcRNfg3hpmt01PG9o9nfMSjRhI6vh_0yhHOWpCXgH0xPfGO4NPtO8rEw8JJJc-jfdBOcuishjrJhQ6FOEw"
          }
        });
        resolve(result.data.url);
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    });
  }

}

export default new DatabaseService();
