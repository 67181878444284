import React, { useRef, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';

import Layout from './../components/Layout';
import Button from './../components/Button';
import Link from './../components/Link';

import TemplateOld from './../components/template/TemplateOld';
import TemplateMulti from './../components/template/TemplateMulti';

import DatabaseService from '../services/DatabaseService';

// import {orderGenerator} from './../functions';



const Bar = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 2px 16px #C1CCE838;
  padding: 15px 20px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div{
    display: flex;
    flex-direction: row
  }
`;


const TemplateBlock  = styled.div`
  width: calc(${2560*0.35}px + 20px);
  padding: 10px;
  background-color: #D2DFE0;
  border-radius: 4px;
  display: flex-inline;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 4px;

`;

const TemplateWrapper  = styled.div`
  width: ${2560*0.35}px;
  border-radius: 4px;
  overflow: hidden;
`;

// const Orderer  = styled.div`
//   font-weight: bold;
//   margin-bottom: 10px;
// `;

const TemplateControls  = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;
const TemplateControlsLeft  = styled.div`
  display: flex;
  & > * {
    margin-right: 10px;
  }
`;
const TemplateControlsRight  = styled.div`
  display: flex;
  & > * {
    margin-left: 10px;
  }
`;
const SearchInput = styled.input`
  background-color: rgb(255, 255, 255);
  color: rgb(0, 20, 31);
  padding: 8px 16px;
  border: 1px solid rgb(203, 203, 203);
  min-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 1px;
  font-size: 14px;
  outline: currentcolor none medium;
  box-sizing: border-box;
  width: 100%;
  -moz-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: 4px;
  box-shadow: rgba(104, 127, 226, 0.384) 0px 1px 2px inset;
  margin-right: 10px;
`;

const Pagination  = styled.div`
  text-align: center;
  padding-top: 30px;
`;

const Page  = styled.div`
  display: inline-block;
  padding: 2px 5px;
  margin: 2px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  min-width: 30px;

  border: 1px solid ${props=>props.active ? "#009DE2" : "D8D8D8"} ;
  background-color: ${props=>props.active ? "#009DE2" : "#ffffff"};
  color: ${props=>props.active ? "#ffffff" : "#000000"};
`;


const List = ({type}) => {
  let ref = useRef(null);
  // const {lang} = useParams();
  const [elements, setElements] = useState([]);
  const [brands, setBrands] = useState(null);
  const [importing, setImporting] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [currentBrand, setCurrentBrand] = useState("");
  // const [currentModel, setCurrentModel] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [generating, setGenerating] = useState([]);

  useEffect(() => {
    DatabaseService.listBrands().then((res) => {
      setBrands(res.result);
    });
  }, []);

  const getList = useCallback(() => {
    let filter = {};
    if(currentBrand){
      filter["brand"] = currentBrand;
    }
    if(type){
      filter["type"] = type;
    }
    if(currentStatus){
      filter["status"] = currentStatus;
    }
    DatabaseService.listArchive({
      "limit": 20,
      "query": search,
      "filter": filter
    }).then((res) => {
      setElements(res.result);
      setPage(p => p+1);
    });
  }, [currentBrand, currentStatus, search, type]);

  const getMoreList = () => {
    let filter = {};
    if(currentBrand){
      filter["brand"] = currentBrand;
    }
    if(type){
      filter["type"] = type;
    }
    if(currentStatus){
      filter["status"] = currentStatus;
    }
    DatabaseService.listArchive({
      "limit": 20,
      "query": search,
      "filter": filter,
      "page": page
    }).then((res) => {
      setElements(elements.concat(res.result));
      setPage(page+1);
    });
  }

  useEffect(() => {
    getList();
  }, [getList, type, search, currentBrand, currentStatus]);

  const doImport = async () => {
    setElements([]);
    setPage(null);
    setImporting(true);
    await DatabaseService.import(type).then((res) => {
      setImporting(false);
      getList();
    });    
  }

  const reset = async (id) => {
    await DatabaseService.updateArchive(id, type,{
      status: "new"
    }).then((res) => {
      let tempElements = [...elements];
      tempElements.forEach((e, i) => {
        if(tempElements[i].id === id){
          tempElements[i].status = "new";
        }
      });
      setElements(tempElements);
    });  
  }

  const reject = async (id) => {
    await DatabaseService.delArchive(id, type).then((res) => {
      let tempElements = [...elements];
      tempElements = tempElements.filter((el) => el.id !== id);
      setElements(tempElements);
    });  
  }

  const confirm = async (id) => {
    await DatabaseService.updateArchive(id, type,{
      status: "confirmed"
    }).then((res) => {
      let tempElements = [...elements];
      tempElements.forEach((e, i) => {
        if(tempElements[i].id === id){
          tempElements[i].status = "confirmed";
        }
      });
      setElements(tempElements);
    });  
  }

  const generate = async (id, big = false) => {
    let tempGenerating = [...generating];
    tempGenerating.push(id);
    setGenerating(tempGenerating);

    let url = await DatabaseService.generateGem(id, big ? "66.4" : "44.6"); //66.4 industriali / 44.6 normali
    window.open(url, '_blank');

    tempGenerating = [...generating];
    tempGenerating = tempGenerating.filter(v => v !== id);
    setGenerating(tempGenerating);
  }

  const generateDrive = async (id, big = false) => {
    let tempGenerating = [...generating];
    tempGenerating.push(id);
    setGenerating(tempGenerating);

    let url = await DatabaseService.generateDrive(id, big ? "66.4" : "44.6"); //66.4 industriali / 44.6 normali
    window.open(url, '_blank');

    tempGenerating = [...generating];
    tempGenerating = tempGenerating.filter(v => v !== id);
    setGenerating(tempGenerating);
  }

  const doGenerate = async (big) => {
    elements.forEach((item, i) => {
      setTimeout(() => {
        generate(item.id, big);
      }, 1000);

    });
  }

  return (
    <Layout>
      <div ref={ref}>
        <Bar>
          <div>
           <select onChange={(e) => {setElements([]); setPage(null); setCurrentStatus(e.target.value);}} value={currentStatus}>
             <option /*selected={currentStatus === null}*/ value={""}>Tutti gli stati</option>
             <option value="new">Nuovo</option>
             <option value="confirmed">Confermato</option>
           </select>
            &nbsp;&nbsp;&nbsp;
            <SearchInput type="text" placeholder="Search..." value={search} onChange={(e) => {setElements([]); setPage(null); setSearch(e.target.value); }}/>
            <select onChange={(e) => {setElements([]); setPage(null); setCurrentBrand(e.target.value); }} value={currentBrand}>
              <option /*selected={currentBrand === null}*/ value={""}>Tutte le marche</option>
              {brands ? brands.map((brand) =>
                <option key={brand} value={brand}>{brand}</option>
              ) : null}
            </select>
             {/* &nbsp;&nbsp;&nbsp;
             {currentBrand !== "" ?
              <select style={{"maxWidth": "150px"}} onChange={(e) => {setElements([]); setPage(null); setCurrentModel(e.target.value);}} value={currentModel}>
                <option / *selected={currentModel === null}* / value={""}>Tutti i modelli</option>
                {brands && brands.model ? Object.keys(brands.model).map((name) =>
                  <option key={name} value={name}>{name} ({brands.model[name]})</option>
                ) : null}
              </select>
            : null } */}
          </div>

          <div>
            { type === "gem" ?
            <>
              <div onClick={() => doGenerate()}>
                <Button display="inline-block" variant="green">{generating.length > 0 ? "Generazione..." : "Genera auto"}</Button>
              </div>
            &nbsp;
            &nbsp;
              <div onClick={() => doGenerate(true)}>
                <Button display="inline-block" variant="green">{generating.length > 0 ? "Generazione..." : "Genera camion"}</Button>
              </div>
            </>
            : null }
            &nbsp;
            &nbsp;
            { type === "drive" ?
              <div onClick={() => doImport()}>
                <Button display="inline-block" variant="blue">
                  {importing ?
                    "Importando..."
                  :
                    "Importa"
                  }
                </Button>
              </div>
            : null }
          </div>
        </Bar>

        {elements ? elements.map((element) => (
          <TemplateBlock key={element.id}>
            {/*<Orderer>{orderGenerator(element.brand,element.name)}</Orderer>*/}
            <TemplateWrapper>
              {element.type === "gem" ?
                <TemplateOld item={element} />
              :
                <TemplateMulti editable={false} data={JSON.stringify(element)} />
              }
            </TemplateWrapper>
            <TemplateControls>
              <TemplateControlsLeft>
                {element.status === "confirmed" ?
                  <Button display="inline-block" variant="green-o">Confermato</Button>
                : 
                  <Button display="inline-block" variant="white-o">Nuovo</Button>
                }
              </TemplateControlsLeft>

              <TemplateControlsRight>
                {element.status !== "confirmed" || element.type === "drive"?
                  <div onClick={() => reject(element.id)}>
                    <Button display="inline-block" variant="red">Elimina</Button>
                  </div>
                : null }
                {element.type === "gem" && element.status === "confirmed" ?
                  <div onClick={() => reset(element.id)}>
                    <Button display="inline-block" variant="red">Reset</Button>
                  </div>
                : null }
                {element.type === "drive" ?
                  <Link
                    to={"/edit/"+element.id}
                    display="block"
                  >
                    <Button display="inline-block" variant="white">Modifica</Button>
                  </Link>
                : null }

                {element.type === "gem" && element.status !== "confirmed" ?
                  <div onClick={() => confirm(element.id)}>
                    <Button display="inline-block" variant="blue">Conferma</Button>
                  </div>
                : null }

                {element.type === "drive" && element.status === "confirmed" ?
                  <div onClick={() => generateDrive(element.id, element.size === "Camion")}>
                    <Button display="inline-block" variant="green">{generating.filter(v => v === element.id).length > 0 ? "Generazione..." : "Genera"}</Button>
                  </div>
                : null }
                {element.type === "gem" /*&& element.status === "confirmed" */?
                  <div onClick={() => generate(element.id)}>
                    <Button display="inline-block" variant="green">{generating.filter(v => v === element.id).length > 0 ? "Generazione..." : "Genera auto"}</Button>
                  </div>
                : null }
                {element.type === "gem" /*&& element.status === "confirmed" */?
                  <div onClick={() => generate(element.id, true)}>
                    <Button display="inline-block" variant="green">{generating.filter(v => v === element.id).length > 0 ? "Generazione..." : "Genera camion"}</Button>
                  </div>
                : null }
              </TemplateControlsRight>

            </TemplateControls>
          </TemplateBlock>
        )) : null}

        <Pagination>
          {page ? 
            <Page onClick={() => getMoreList()}>Load More</Page>
          : null }
        </Pagination>
      </div>
    </Layout>
  )
}

export default List;
