import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`
  display: ${props => props.display};
  text-decoration: none;
  color: inherit;
`;

const A = ({href, target, display, children}) => {
  return (
    <Wrapper href={href} target={target} display={display}>
      {children}
    </Wrapper>
  )
}

export default A;
